import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TextResponse } from "../../models/responseData";
import { cleanString } from "../../utils/formatString";
import { determineResponseTypeTextResponse } from "../../utils/determineResponseType";


const Subsection2 = () => {
  const [transformedResponse, setTransformedResponse] = useState<TextResponse | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year, institution } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section9/subsection2/?report_year=${year}&group_by=exit_status`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTextResponse(data)) {
        setTransformedResponse(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently]);

  return (
    <>
      <h2>9.2 Comments about changes to work or employment made in a preemptive action (Open-text Response)</h2>
      <h3>How, if at all, do preemptive retention actions influence faculty members" plans to remain?</h3>
      <div className="grid">
        <div className="grid-row-title">
          <em>Prompt:</em>
        </div>
        <div>
          How, if at all, have the recent change(s) to your work or employment influenced your plans to remain at <span className="red-variable-name">{institution}</span>?
        </div>
        <div className="grid-row-title">
          <em>Paths:</em>
        </div>
        <div>
          Preemptive retentions
        </div>
      </div>

      <h4>Preemptive Retentions</h4>
      {transformedResponse && transformedResponse.institution.preemptive_retention &&
        <>
          {transformedResponse.institution.preemptive_retention.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.preemptive_retention.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }

      
    </>
  )
}

export default Subsection2;