import { NestedResponseData, TripleNestedResponseData, FactorsResponse } from "../models/responseData";
import { answerChoiceLegendDomain } from "./legendMapping/answerChoiceLegend";
import { Categories, getModifedCategories, getNameFromVariable } from "./legendMapping/categoryLegend";
import { retentionCategoriesMap } from "./legendMapping/categoryLegend";
import { formatLabels, convertToDecimal, getLegendDomain } from "./utils"

const typeDomain = ["Your Institution", "Cohort"];

const retentionCategoriesValues = Object.values(retentionCategoriesMap);

export interface TransformedData {
  description: string;
  answerChoice?: string;
  category?: string;
  type: string;
  value?: number;
  respondent_status?: string;
  selection?: string;
  total?: number;
  retention_status?: string;
}

export interface TransformedDataSlopeGraph {
  category: string;
  type: string;
  "median_percent_change": number;
}

export const transformDataStackedByRetention = (data: NestedResponseData, filter: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;
  let modifiedCategories = getModifedCategories();
  let categoryList = modifiedCategories[filter as keyof Categories];

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      total = data[type].total;
      if (category !== "total") {
        for (const description of Object.keys(data[type][category])) {
          let formattedDescription = getNameFromVariable(filter, description);

          transformedData.push({
            description: formattedDescription,
            category: formatLabels(category),
            type: formatLabels(type),
            value: convertToDecimal(data[type][category][description], total[description]),
            total: total[description]
          });
        }
      }
    }
  }

  transformedData.sort((a: any, b: any) => {

    const categoryComparison = categoryList.indexOf(a.description) - categoryList.indexOf(b.description);

    // If descriptions are in different positions, return the comparison result
    if (categoryComparison !== 0) {
      return categoryComparison;
    }

    // If descriptions are in the same position, compare by category
    return (retentionCategoriesValues.indexOf(a.category) > retentionCategoriesValues.indexOf(b.category)) ? 1 : -1;
  })

  if (filter === "exit_status") {
    return transformedData.filter((item) => item.description === "Total")
  }

  return transformedData;
}

export const transformDataStackedByRetentionNested = (data: TripleNestedResponseData, filter: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;
  // let categoryList = categories[filter as keyof Categories];

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      if (category !== "total") {
        for (const description of Object.keys(data[type][category])) {
          for (const retention_status of Object.keys(data[type][category][description])) {
            if (retention_status !== "total") {
              total = data[type][category][description].total;
              transformedData.push({
                description: formatLabels(description),
                category: formatLabels(category),
                type: formatLabels(type),
                value: convertToDecimal(data[type][category][description][retention_status], total),
                retention_status: formatLabels(retention_status)
              });
            }

          }
        }
      }
    }
  }
  return transformedData;
}


export interface TransformedDataBellChart {
  type: string,
  category: string,
  mean: number,
  stdev: number,
  count: number,
}

export const transformDataBellChart = (data: NestedResponseData, filter: string): TransformedDataBellChart[] => {
  const transformedData: TransformedDataBellChart[] = [];

  for (const type in data) {
    for (const category in data[type]) {
      const { count, mean, stdev } = data[type][category];
      let formattedCategory = formatLabels(category);
      transformedData.push({
        type: formatLabels(type),
        category: formattedCategory,
        mean: Math.round(mean), // Rounding to an integer
        stdev: Math.round(stdev), // Rounding to an integer
        count,
      });
    }
  }
  return transformedData;
}

export interface TransformedDataFactors {
  type: string,
  category: string,
  factor: string,
  frequency: number,
  ranking: string,
  total: number
}

export const transformDataFactors = (data: FactorsResponse, filter: string, factorOrder: any[]): TransformedDataFactors[] => {
  const transformedData: TransformedDataFactors[] = [];

  data.factors.forEach((factorEntry) => {
    Object.entries(factorEntry).forEach(([factor, categoryData]) => {
      Object.entries(categoryData).forEach(([category, typeData]) => {
        Object.entries(typeData).forEach(([type, rankingData]) => {
          Object.entries(rankingData).forEach(([ranking, frequency]) => {
            let formattedDescription = getNameFromVariable(filter, category);

            if (Number(ranking) >= 1 && Number(ranking) <= 5) {
              const total = rankingData.num_selected || 1;
              const entry: TransformedDataFactors = {
                factor: formatLabels(factor),
                category: formattedDescription,
                ranking,
                frequency: convertToDecimal(frequency, total),
                type: formatLabels(type),
                total: rankingData.num_selected
              };

              transformedData.push(entry);
            }
          });
        });
      });
    });
  });

  return transformedData;
}

export interface TransformedDataFactorsAverageRank {
  factors: string,
  reason?: string,
  type: string,
  category: string,
  frequency: number,
  avg_rank: number,
}

const determineResponseTypeTripleNestedResponseData = (response: any): response is TripleNestedResponseData => true;

const determineResponseTypeNestedResponseData = (response: any): response is NestedResponseData => true;

export const transformDataFactorsAverageRank = (
  data: NestedResponseData | TripleNestedResponseData,
  factor: string,
  filter: string,
  splitByReason?: boolean
): TransformedDataFactorsAverageRank[] => {
  const transformedData: TransformedDataFactorsAverageRank[] = [];
  let frequency;
  let categoryList = getModifedCategories("totalFirst")[filter as keyof Categories];

  // 2.2
  if (splitByReason && determineResponseTypeTripleNestedResponseData(data)) {
    for (const type of Object.keys(data)) {
      for (const reason of Object.keys(data[type])) {
        for (const category of Object.keys(data[type][reason])) {
          frequency = convertToDecimal(data[type][reason][category].num_selected, data[type][reason][category].total);
          let formattedCategory = getNameFromVariable(filter, category);
          if (frequency !== 0 && data[type][reason][category].avg_rank !== 0) {
            let avg_rank = data[type][reason][category].avg_rank;

            // filter out ranks not in 1.0-5.0 range
            if (avg_rank < 1 || avg_rank > 5) {
              continue;
            }
            transformedData.push({
              factors: factor,
              reason: reason === "stay" ? "Reason to Stay" : "Reason to Leave",
              type: formatLabels(type),
              category: formattedCategory,
              frequency: frequency,
              avg_rank: avg_rank
            });
          }
        }
      }
    }
    //  6.4
  } else if (!splitByReason && determineResponseTypeNestedResponseData(data)) {
    for (const type of Object.keys(data)) {
      for (const category of Object.keys(data[type])) {
        frequency = convertToDecimal(data[type][category].num_selected, data[type][category].total);
        let formattedCategory = getNameFromVariable(filter, category);
        if (frequency !== 0 && data[type][category].avg_rank !== 0) {
          let avg_rank = data[type][category].avg_rank;

          transformedData.push({
            factors: factor,
            type: formatLabels(type),
            category: formattedCategory,
            frequency: frequency,
            avg_rank: avg_rank
          });
        }
      }

    }
  }

  transformedData.sort((a: any, b: any) => {
    return categoryList.indexOf(a.category) > categoryList.indexOf(b.category) ? 1 : -1;
  });

  return transformedData;
}


export const transformDataBarChart = (data: NestedResponseData, section: string, filter: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;
  let domainCopy = getLegendDomain(section);


  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      if (category !== "total") {
        for (const description of Object.keys(data[type][category])) {
          total = data[type][category].total;
          let formattedCategory = getNameFromVariable(filter, category);
          if (description !== "total") {
            transformedData.push({
              description: answerChoiceLegendDomain[section][description],
              category: formattedCategory,
              type: formatLabels(type),
              value: convertToDecimal(data[type][category][description], total),
            });
          }
        }
      }
    }
  }
  transformedData.sort((a: any, b: any) => {
    if (a.description !== b.description) {
      return domainCopy.indexOf(a.description) > domainCopy.indexOf(b.description) ? 1 : -1;
    } else {
      return typeDomain.indexOf(a.type) > typeDomain.indexOf(b.type) ? 1 : -1;
    }
  });

  return transformedData;
}

export const transformDataBarChartTripleNested = (data: TripleNestedResponseData, section: string, filter: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      total = data[type][category].total as unknown as number;

      if (category !== "total") {
        for (const description of Object.keys(data[type][category])) {

          for (const status of Object.keys(data[type][category][description])) {
            if (status !== "total") {
              // total = data[type][category][description].total;
              transformedData.push({
                description: formatLabels(description),
                // category: formatLabels(category),
                category: getNameFromVariable(filter, category),
                type: formatLabels(type),
                value: convertToDecimal(data[type][category][description][status], total),
              });
            }

          }
        }
      }
    }
  }
  return transformedData;
}

export const transformDataStackedByAnswer = (data: NestedResponseData, section: string, filter: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;

  let domainCopy = getLegendDomain(section);
  let modifiedCategories = getModifedCategories("No Total");
  let categoryList = modifiedCategories[filter as keyof Categories];

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      let formattedCategory = getNameFromVariable(filter, category)

      if (category !== "total") {
        for (const description of Object.keys(data[type][category])) {
          total = data[type][category].total;
          if (description !== "total") {
            transformedData.push({
              description: answerChoiceLegendDomain[section][description],
              category: formattedCategory,
              type: formatLabels(type),
              value: convertToDecimal(data[type][category][description], total),
              total: total
            });
          }
        }
      }
    }
  }

  transformedData.sort((a: any, b: any) => {
    const categoryComparison = domainCopy.indexOf(a.description) - domainCopy.indexOf(b.description);

    // If descriptions are in different positions, return the comparison result
    if (categoryComparison !== 0) {
      return categoryComparison;
    }

    // If descriptions are in the same position, compare by category
    return (categoryList.indexOf(a.category) > categoryList.indexOf(b.category)) ? 1 : -1;
  })

  return transformedData;
}

export const transformDataStackedBySelected = (data: NestedResponseData, filter: string, section: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;
  let domainCopy = getLegendDomain(section);

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      if (category !== "total") {
        for (const description of Object.keys(data[type][category])) {
          total = data[type][category].total;
          let formattedCategory = getNameFromVariable(filter, category);
          if (description !== "total") {
            let value = convertToDecimal(data[type][category][description], total)
            transformedData.push({
              description: answerChoiceLegendDomain[section][description],
              category: formattedCategory,
              type: formatLabels(type),
              value: value,
              selection: "Selected"
            }
            );
          }
        }
      }
    }
  }

  transformedData.sort((a: any, b: any) => {
    if (a.description !== b.description) {
      return domainCopy.indexOf(a.description) > domainCopy.indexOf(b.description) ? 1 : -1;
    } else {
      return typeDomain.indexOf(a.type) > typeDomain.indexOf(b.type) ? 1 : -1;
    }
  });
  return transformedData;
}

export const transformTripleNestedDataStackedByAnswer = (data: TripleNestedResponseData, filter: string, section: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;
  let domainCopy = getLegendDomain(section);


  for (const category of Object.keys(data)) {
    for (const type of Object.keys(data[category])) {
      if (type !== "total") {
        for (const description of Object.keys(data[category][type])) {

          for (const answerChoice of Object.keys(data[category][type][description])) {
            total = data[category][type][description].total;
            if (description !== "total" && answerChoice !== "total") {
              let formattedDescription = getNameFromVariable(filter, description);

              transformedData.push({
                description: formattedDescription,
                answerChoice: answerChoiceLegendDomain[section][answerChoice],
                category: formatLabels(category),
                type: formatLabels(type),
                value: convertToDecimal(data[category][type][description][answerChoice], total),
                total: total
              });
            }
          }
        }
      }
    }
  }

  transformedData.sort((a: any, b: any) => {
    return (domainCopy.indexOf(a.answerChoice) > domainCopy.indexOf(b.answerChoice)) ? 1 : -1
  })

  return transformedData;
}

export const transformTripleNestedDataStackedByRetentionRespondent = (data: TripleNestedResponseData, filter: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let total;

  let modifiedCategories = getModifedCategories("No Total");
  let categoryList = modifiedCategories[filter as keyof Categories];


  for (const category of Object.keys(data)) {
    if (category !== "overall") {
      for (const type of Object.keys(data[category])) {
        total = 0;
        for (const respondent_status of Object.keys(data[category][type])) {
          for (const retention_status of Object.keys(data[category][type][respondent_status])) {
            if (retention_status === "total") {
              total += data[category][type][respondent_status][retention_status];
            }
          }
        }

        let formattedDescription = getNameFromVariable(filter, category);

        for (const respondent_status of Object.keys(data[category][type])) {
          for (const retention_status of Object.keys(data[category][type][respondent_status])) {
            let value = convertToDecimal(data[category][type][respondent_status][retention_status], total);

            // keep the total value for redacted columns
            if (retention_status === "total") {
              value = 0;
            }
            transformedData.push({
              description: formattedDescription,
              respondent_status: formatLabels(respondent_status) + " - " + formatLabels(retention_status),
              type: formatLabels(type),
              value: value,
              total: total
            });
          }
        }
      }
    }
  }

  transformedData.sort((a: any, b: any) => {
    return categoryList.indexOf(a.description) - categoryList.indexOf(b.description)
  })

  return transformedData;
}


export const transformTripleNestedDataStackedByRetention = (data: TripleNestedResponseData, filter: string, section: string): [TransformedData[], string[]] => {
  const transformedData: TransformedData[] = [];
  let domain: any[] = [];
  domain = getLegendDomain(section, true);

  let validCategoriesForFilter: string[] = [];

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      let total = 0;
      let departureTotal = 0;
      let retentionTotal = 0;

      let exitStatusDepartureTotal = 0;
      let exitStatusRetentionTotal = 0;

      if (filter === "exit_status") {
        exitStatusDepartureTotal = data[type].departure.total.total as unknown as number;
        exitStatusRetentionTotal = data[type].retention.total.total as unknown as number;
      }

      departureTotal = data[type][category].total.departure;
      retentionTotal = data[type][category].total.retention;


      for (const description of Object.keys(data[type][category])) {
        if (description !== "total") {
          for (const retention_status of Object.keys(data[type][category][description])) {
            if (retention_status !== "total" && retention_status !== "preemptive_retention") {
              let formattedCategory = getNameFromVariable(filter, category)

              if (filter === "exit_status") {
                if (!validCategoriesForFilter.includes("Total")) {
                  validCategoriesForFilter.push("Total");
                }

                if (retention_status === "departure") {
                  total = exitStatusDepartureTotal;
                } else if (retention_status === "retention") {
                  total = exitStatusRetentionTotal;
                }

                transformedData.push({
                  category: "Total",
                  description: description,
                  retention_status: formatLabels(retention_status),
                  type: formatLabels(type),
                  value: convertToDecimal(data[type][category][description][retention_status], total),
                });

              } else {
                if (!validCategoriesForFilter.includes(formattedCategory)) {
                  validCategoriesForFilter.push(formattedCategory);
                }

                if (retention_status === "departure") {
                  total = departureTotal;
                } else if (retention_status === "retention") {
                  total = retentionTotal;
                }

                transformedData.push({
                  category: formattedCategory,
                  description: description,
                  retention_status: formatLabels(retention_status),
                  type: formatLabels(type),
                  value: convertToDecimal(data[type][category][description][retention_status], total),
                });
              }
            }
          }
        }
      }
    }
  }


  transformedData.sort((a: any, b: any) => {
    const categoryComparison = domain.indexOf(a.description) - domain.indexOf(b.description);

    if (categoryComparison !== 0) {
      return categoryComparison;
    }

    const retentionStatusComparison = retentionCategoriesValues.indexOf(a.retention_status) - retentionCategoriesValues.indexOf(b.retention_status);

    if (retentionStatusComparison !== 0) {
      return retentionStatusComparison;
    }

    return typeDomain.indexOf(a.type) - typeDomain.indexOf(b.type);
  });

  for (const item of transformedData) {
    item.description = formatLabels(item.description);
  }

  const categoryDict = getModifedCategories("No Total");

  // for filter = exit_status, only show the overall data
  const adjustedExitStatusCategoryDict = {
    ...categoryDict,
    exit_status: ["Total"]
  }
  const categoryList = adjustedExitStatusCategoryDict[filter as keyof Categories];

  validCategoriesForFilter.sort((a: any, b: any) => categoryList.indexOf(a) - categoryList.indexOf(b));

  return [transformedData, validCategoriesForFilter];
}

export const transformDataDoubleBarChart = (data: NestedResponseData, section: string, filter: string): TransformedData[] => {
  const transformedData: TransformedData[] = [];
  let domainCopy = getLegendDomain(section);
  let total;

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      total = data[type][category].total;
      for (const description of Object.keys(data[type][category])) {
        if (description !== "total") {
          let formattedCategory = getNameFromVariable(filter, category);

          transformedData.push({
            description: answerChoiceLegendDomain[section][description],
            category: formattedCategory,
            type: formatLabels(type),
            value: convertToDecimal(data[type][category][description], total)
          });
        }
      }
    }
  }

  transformedData.sort((a: any, b: any) => {
    return (domainCopy.indexOf(a.description) > domainCopy.indexOf(b.description)) ? 1 : -1
  })
  return transformedData;
}

export const transformDataSlopeChart = (data: NestedResponseData, filter: string) => {
  const transformedData: TransformedDataSlopeGraph[] = [];
  let categoryList = getModifedCategories("totalFirst")[filter as keyof Categories];

  for (const type of Object.keys(data)) {
    for (const category of Object.keys(data[type])) {
      if (category !== "preemptive_retention") {
        let formattedCategory = getNameFromVariable(filter, category);

        transformedData.push({
          type: formatLabels(type),
          category: formattedCategory,
          median_percent_change: data[type][category].median_percent_change || 0
        });
      }
    }
  }

  transformedData.sort((a: any, b: any) => {
    return (categoryList.indexOf(a.category) > categoryList.indexOf(b.category)) ? 1 : -1
  })

  return transformedData;

}