import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import { roundNumber, formatCurrencyString } from "../../utils/utils";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import PageLoader from "../../components/PageLoader";
import { retentionCategoriesWithOverallMap } from "../../utils/legendMapping/categoryLegend";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";


const GrantsFromExternalSources = () => {
  const [responseA, setResponseA] = useState<NestedResponseData | null>(null);
  const [responseB, setResponseB] = useState<NestedResponseData | null>(null);
  const tableRefA = useRef<HTMLTableElement>(null);
  const tableRefB = useRef<HTMLTableElement>(null);
  const { year } = useParams();
  const [loadingA, setLoadingA] = useState(true);
  const [loadingB, setLoadingB] = useState(true);
  const [tableA, setTableA] = useState<HTMLTableElement | null>(null);
  const [tableB, setTableB] = useState<HTMLTableElement | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const getDataSectionA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrlA = `appendix/subsection6a/?report_year=${year}`;

      let { data, error } = await getProtectedResource(accessToken, requestUrlA);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseA(data);
        setLoadingA(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataSectionB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrlB = `appendix/subsection6b/?report_year=${year}`;

      let { data, error } = await getProtectedResource(accessToken, requestUrlB);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseB(data);
        setLoadingB(false);
      }
      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataSectionA();
    getDataSectionB();

  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (tableRefA.current) {
      setTableA(tableRefA.current);
    }
  }, [responseA]);

  useEffect(() => {
    if (tableRefB.current) {
      setTableB(tableRefB.current);
    }
  }, [responseB]);

  return (
    <>
      <h2>Grants from external sources</h2>
      <p>
        What was the number and total value of grants received by the eligible population in the five years prior to this survey? <em>(Available only to institutions who provided <span className="red-variable-name">grants_number</span> and <span className="red-variable-name">grants_value</span> to COACHE.)</em>
      </p>

      <h4>a. Number of Grants</h4>

      {!responseA && <PageLoader />}

      {responseA &&
        <>
          <table className="grants-table" ref={tableRefA}>
            <thead>
              <tr>
                <th></th>
                <th colSpan={7}>Your Institution</th>
              </tr>
            </thead>
            <thead className="column-headers">
              <tr>
                <th className="row-header"></th>
                <th>Valid n</th>
                <th>Mean</th>
                <th>SD</th>
                <th>Min</th>
                <th>Median</th>
                <th>Max</th>
                <th>Sum</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(retentionCategoriesWithOverallMap).map((retentionStatus, i) => {
                let rowClassName = "";
                if (retentionStatus === "overall") {
                  rowClassName = "grand-total-row"
                }

                let current;
                if (responseA.institution[retentionStatus]) {
                  current = responseA.institution[retentionStatus];
                }
                return (
                  <tr className={rowClassName} key={i}>
                    <th className="row-header">{retentionCategoriesWithOverallMap[retentionStatus]}</th>
                    <td>{current ? current.count : ""}</td>
                    <td>{current && current.count ? roundNumber(current.mean) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.stdev, true) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.min) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.median) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.max) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.sum) : ""}</td>
                  </tr>)
              }
              )}
            </tbody>
          </table>
          <TableToCSVConverter
            table={tableA}
            filename="Appendix_6a_Grants_from_external_sources"
            loading={loadingA}
            setLoading={setLoadingA}
          />
        </>
      }
      <h4>b. Value of Grants</h4>

      {!responseB && <PageLoader />}

      {responseB &&
        <>
          <table className="grants-table" ref={tableRefB}>
            <thead className="column-headers">
              <tr>
                <th className="row-header"></th>
                <th>Valid n</th>
                <th>Mean</th>
                <th>SD</th>
                <th>Min</th>
                <th>Median</th>
                <th>Max</th>
                <th>Sum</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(retentionCategoriesWithOverallMap).map((retentionStatus, i) => {
                let rowClassName = "";
                if (retentionStatus === "overall") {
                  rowClassName = "grand-total-row"
                }

                let current;
                if (responseB.institution[retentionStatus]) {
                  current = responseB.institution[retentionStatus];
                }
                return (
                  <tr className={rowClassName} key={i}>
                    <th className="row-header">{retentionCategoriesWithOverallMap[retentionStatus]}</th>
                    <td>{current ? current.count : ""}</td>
                    <td>{current ? formatCurrencyString(current.mean) : ""}</td>
                    <td>{current ? formatCurrencyString(current.stdev) : ""}</td>
                    <td>{current ? formatCurrencyString(current.min) : ""}</td>
                    <td>{current ? formatCurrencyString(current.median) : ""}</td>
                    <td>{current ? formatCurrencyString(current.max) : ""}</td>
                    <td>{current ? formatCurrencyString(current.sum) : ""}</td>
                  </tr>)
              }
              )}
            </tbody>
          </table>
          <TableToCSVConverter
            table={tableB}
            filename="Appendix_6b_Grants_from_external_sources"
            loading={loadingB}
            setLoading={setLoadingB}
          />
        </>
      }



    </>
  )
}

export default GrantsFromExternalSources;