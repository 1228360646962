import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { determineNdisplay, convertToPercentString } from "../../utils/utils";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import PageLoader from "../../components/PageLoader";
import { determineResponseTypeInstitutionResponseData } from "../../utils/determineResponseType";
import { InstitutionResponseData } from "../../models/responseData";
import { cleanString } from "../../utils/formatString";


const SubSection1 = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [response, setResponse] = useState<InstitutionResponseData | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState<HTMLTableElement | null>(null);


  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection1/?report_year=${year}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeInstitutionResponseData(data)) {
        setResponse(data);
        setLoading(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }

    };

    getData();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (tableRef.current) {
      setTable(tableRef.current);
    }
  }, [response]);

  return (
    <>
      <h2>6.1 Origin of outside offers</h2>
      <p>Where are the most serious outside offers originating? (Top 20 institutions)</p>

      <p>The table reflects data from both population file and survey. It does not indicate survey completion.</p>

      <h4>Your Institution</h4>

      {!response && <PageLoader />}

      {response &&
        <>
          <table ref={tableRef}>
            <thead>
              <tr className="column-headers">
                <th></th>
                <th>#</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {response.institutions.map((institution, i) =>
                <tr key={i}>
                  <th className="row-header">{cleanString(institution.institution)}</th>
                  <td>{determineNdisplay(institution.count, false, true)}</td>
                  <td>{convertToPercentString(institution.count, response.total)}</td>
                </tr>
              )}
              <tr className="grand-total-row">
                <th className="row-header">Total</th>
                <td>{determineNdisplay(response.total, false, true)}</td>
                <td>100%</td>
              </tr>
            </tbody>
          </table>
          <TableToCSVConverter
            table={table}
            filename="6.1_Origin_of_outside_offers"
            loading={loading}
            setLoading={setLoading}
          />
        </>
      }

      
    </>
  )
}

export default SubSection1;