import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TextResponse } from "../../models/responseData";
import { cleanString } from "../../utils/formatString";
import { determineResponseTypeTextResponse } from "../../utils/determineResponseType";


const TextBasedResponses = () => {
  const [transformedResponse, setTransformedResponse] = useState<TextResponse | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year, institution } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section2/subsection3/?report_year=${year}&group_by=exit_status`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTextResponse(data)) {
        setTransformedResponse(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently]);

  return (
    <>
      <h2>2.3 Comments on whether to stay or accept the outside offer (Open-text Response)</h2>
      <h3>What factors weigh most heavily on the decision to stay or to accept the outside offer?</h3>

      <div className="grid">
        <div className="grid-row-title">
          <em>Prompt:</em>
        </div>
        <div>
          Think back to the time you received the outside offer, but before any counteroffer was (or was not) made. At that time, what factors were weighing most heavily on your consideration of whether to stay at <span className="red-variable-name">{institution}</span> or accept the outside offer?
        </div>
        <div className="grid-row-title">
          <em>Paths:</em>
        </div>
        <div>
          Departures and retentions
        </div>
      </div>

      <h4>Departures</h4>
      {transformedResponse && transformedResponse.institution.departure &&
        <>
          {transformedResponse.institution.departure.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.departure.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }

      <h4>Retentions</h4>
      {transformedResponse && transformedResponse.institution.retention &&
        <>
          {transformedResponse.institution.retention.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.retention.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }
      
    </>
  )
}

export default TextBasedResponses;