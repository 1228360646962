import { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import {
  TransformedData,
  transformTripleNestedDataStackedByRetentionRespondent,
} from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import StackedByRetentionRespondentStatus from "../../charts/stackedBarChart/stackedByRetentionRespondentStatus";
import PageLoader from "../../components/PageLoader";
import InstitutionN from "../../components/InstitutionN";
import { determineResponseTypeTripleNestedResponseData } from "../../utils/determineResponseType";


const ResponseRates = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [transformedResponse, setTransformedResponse] = useState<TransformedData[] | null>(null);

  const [institutionN, setInstitutionN] = useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const { reportType, year } = useParams();
  const location = useLocation();
  const splitPath = location.pathname.split("/");
  const path = splitPath[1] + "/" + splitPath[2];

  useEffect(() => {
    const getInstitutionN = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `appendix/subsection9a/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTripleNestedResponseData(data)) {
        setInstitutionN(data.overall.institution.non_respondent.total + data.overall.institution.respondent.total);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getInstitutionN();
  }, [getAccessTokenSilently]);


  useEffect(() => {
    const getDataChart = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `appendix/subsection9a/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTripleNestedResponseData(data)) {
        setTransformedResponse(transformTripleNestedDataStackedByRetentionRespondent(data, selectedFilter));
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataChart();
  }, [getAccessTokenSilently, selectedFilter]);

  return (
    <>
      <h2>1.3 Response rates</h2>
      <p>
        What are the population and respondent characteristics of your university and of the comparison cohort?
      </p>

      <p className="disclaimer-note">
        Please refer to the Appendix section <Link id="link-text" to={`/${path}/${reportType}/${year}/Appendix/TheAnalyticSample-ResponseRates`}>(Response Rates)</Link> for the full table.
      </p>

      {!transformedResponse && <PageLoader />}
      
      {transformedResponse &&
        <>
          <InstitutionN institutionN={institutionN} />
          <FilterDropdown setSelectedFilter={setSelectedFilter} />
          <StackedByRetentionRespondentStatus data={transformedResponse} selectedFilter={selectedFilter} />
        </>
      }
    </>
  )
}

export default ResponseRates;