import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import { getLegendDomain } from "../../utils/utils";
import { TransformedData } from "../../utils/transformDataUtils";
import { determineFill, getColorScale, determineTextLabelDisplay } from "../shared/colorSchemeUtils";
import { getInstitutionCohortDomain } from "../shared/getInstitutionCohortDomain";
import { defaultXscaleBarChart, plotStyle } from "../shared/observablePlotUtils";
import { makeCategoryNDict } from "../../utils/utils";

interface StackedByAnswerProps {
    data: TransformedData[];
    section: string;
    sequential?: boolean;
    institutionN: number;
    selectedFilter: string;
}

const StackedByAnswer: FC<StackedByAnswerProps> = ({ data, section, sequential, institutionN, selectedFilter }) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const fillDomain = getLegendDomain(section);
        const fyDomain = getInstitutionCohortDomain(institutionN);

        let chart;

        let colorScale = getColorScale(sequential, fillDomain);
        const categoryNdict = makeCategoryNDict(selectedFilter, data, "category", "No Total");

        if (plotContainerRef.current && data) {
            chart = Plot.plot({
                marks: [
                    Plot.barX(data, {
                        x: "value",
                        y: "category",
                        fy: "type",
                        fill: "description",
                        sort: {
                            fy: { value: "y", order: null },
                            y: { value: "y", order: null }
                        },
                        insetTop: 0.2,
                        insetBottom: 0.2,
                        stroke: sequential ? "white" : "none",
                        strokeWidth: .75,
                        title: (d) => determineTextLabelDisplay(d, "tooltip", "description"),
                        offset: "normalize"
                    }),
                    Plot.textX(data,
                        Plot.stackX({
                            text: d => determineTextLabelDisplay(d, "label", "description"),
                            x: "value",
                            y: "category",
                            fy: "type",
                            fill: d => determineFill(d, sequential, colorScale, fillDomain),
                        })
                    ),
                    Plot.text(data.filter((item: any) => item.type === "Your Institution"), {
                        y: "category",
                        fy: "type",
                        text: d => {
                            if (d.total === categoryNdict[d.category] && d.total !== 0)
                                return `n = ${d.total}`
                        },
                        frameAnchor: "top-left",
                        dx: -200,
                        dy: -5
                    }),
                    Plot.axisY({
                        lineWidth: 15,
                        label: null,
                    }),
                    Plot.axisFy({
                        lineWidth: 6,
                        anchor: "left",
                        tickPadding: 225,
                        label: null,
                        fontWeight: "bold"
                    }),
                ],
                width: 800,
                marginLeft: 275,
                x: defaultXscaleBarChart,
                y: { label: null },
                fy: { domain: fyDomain },
                color: colorScale,
                style: plotStyle,
            });
            plotContainerRef.current.innerHTML = "";
            plotContainerRef.current.appendChild(chart);
        }
    }, [data, section])

    return (
        <div ref={plotContainerRef} />
    )
}

export default StackedByAnswer;