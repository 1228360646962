import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import Subsection1 from "./Subsection1";
import Subsection2 from "./Subsection2";
import Subsection3 from "./Subsection3";
import Subsection4 from "./Subsection4";
import Subsection5 from "./Subsection5";
import GrantsFromExternalSources from "./Subsection6";
import AcademicAnalytics from "./Subsection7";
import MissingData from "./Subsection8";
import ResponseRates from "./Subsection9";
import Subsection6oldTable from "./Subsection10";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface AppendixProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const Appendix: FC<AppendixProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("Appendix", true);
  }, [])

  return (
    <>
      <BreadcrumbNav section="Appendix" subsection={subsection} />

      <h1>Appendix</h1>
      {subsection === "BriefSummary" && <Introduction />}
      {subsection === "EligiblePopulation" && <Subsection1 />}
      {subsection === "Administration" && <Subsection2 />}
      {subsection === "ReportingAnalysis" && <Subsection3 />}
      {subsection === "References" && <Subsection4 />}
      {subsection === "Contact" && <Subsection5 />}
      {subsection === "TheAnalyticSample-GrantsFromExternalSources" && <GrantsFromExternalSources />}
      {subsection === "TheAnalyticSample-AcademicAnalytics" && <AcademicAnalytics />}
      {subsection === "TheAnalyticSample-MissingData" && <MissingData />}
      {subsection === "TheAnalyticSample-ResponseRates" && <ResponseRates />}
      {subsection === "NegotiationTerms-RankTenureTitle" && <Subsection6oldTable />}

      <BottomNavigation />
    </>
  )
}

export default Appendix;