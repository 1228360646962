import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { OtherTextResponse } from "../../models/responseData";
import { determineResponseTypeOtherTextResponse } from "../../utils/determineResponseType";


const InitialContactResponses = () => {
  const [transformedResponse, setTransformedResponse] = useState<OtherTextResponse | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section4/subsection4/other/?report_year=${year}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeOtherTextResponse(data)) {
        setTransformedResponse(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently]);

  return (
    <>
      <h2>Responses to “Other (please specify)”</h2>
      <h3>Concerning the most recent job offer you received, how was communication first initiated between you and a representative from that institution?</h3>
      {transformedResponse && transformedResponse.institution &&
        <>
          {transformedResponse.institution.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.map((response: string, i: number) =>
                <li key={i}>{response}</li>
              )}
            </ul>
          }
        </>
      }
      
    </>
  )
}

export default InitialContactResponses;