import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getProtectedResource } from "../../services/apiRequest.service";
import { transformDataFactorsAverageRank, TransformedDataFactorsAverageRank } from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import DotChart from "../../charts/dotChart/dotChart";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import { defaultFactorCombinations } from "./defaultFactorCombinations";
import { formatString } from "../../utils/formatString";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeFactorsListResponse, determineResponseTypeTripleNestedResponseData } from "../../utils/determineResponseType";

interface FactorsResponseObject {
  [key: string]: TransformedDataFactorsAverageRank[];
}

const AverageRank = () => {
  const [categoryFilter, setCategoryFilter] = useState("exit_status");
  const [selectedFactor, setSelectedFactor] = useState("");
  const [selectedFactors, setSelectedFactors] = useState<string[]>([]);
  const [institutionN, setInstitutionN] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedCombination, setSelectedCombination] = useState("default");
  const [factorCombinations, setFactorCombinations] = useState(defaultFactorCombinations);
  const [responseObject, setResponseObject] = useState<FactorsResponseObject | null>(null);
  const [apiResponse, setApiResponse] = useState<any | null>(null);
  const [apiResponseCsvDownload, setApiResponseCsvDownload] = useState<any>({});

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getInstitutionN = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section2/subsection1?report_year=${year}&factor=benefits`
      const { data } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTripleNestedResponseData(data)) {
        setInstitutionN(data.institution.stay.overall.total);
        setLoading(false);
      }
    };
    getInstitutionN();
  }, []);

  useEffect(() => {
    const getFactorsList = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section2/subsection1/list_factors?report_year=${year}`
      const { data } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeFactorsListResponse(data)) {
        let sortedTop5Factors = data.all
          .filter((factor) => factor.reason !== "no_compelling_factors")
          .sort((a, b) => b.count - a.count).slice(0, 5);

        let reasons: string[] = [];
        sortedTop5Factors.forEach((factor) => { reasons.push(factor.reason) });

        setFactorCombinations({
          ...factorCombinations,
          top_5_most_selected_factors: reasons
        })
      }
    };
    getFactorsList();
  }, []);


  const getDataForFactor = async (factor: string) => {
    const accessToken = await getAccessTokenSilently();
    const requestUrl = `section2/subsection1/?report_year=${year}&group_by=${categoryFilter}&factor=${factor}`
    const { data, error } = await getProtectedResource(accessToken, requestUrl);

    if (data && determineResponseTypeTripleNestedResponseData(data)) {
      setResponseObject(prevState => ({
        ...prevState,
        [factor]: transformDataFactorsAverageRank(data, factor, categoryFilter, true)
      }));
      setApiResponse((prevState: any) => ({
        ...prevState,
        [factor]: data
      }));

      if (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleFactorChange = (factor: string, limit: number) => {

    if (selectedFactors.includes(factor)) {
      // Remove the factor if it's already selected
      setSelectedFactors(selectedFactors.filter((selected) => selected !== factor));
    } else {
      // Add the factor if it's not already selected and the limit is not reached
      if (selectedFactors.length < limit) {
        setSelectedFactors([...selectedFactors, factor]);
        setSelectedFactor(factor);
      }
    }
  }

  const fetchAndSetFactors = async (factors: any) => {
    for (const factor of factors) {
      await Promise.resolve(); // Ensures that the state update is processed synchronously
      setSelectedFactor(factor);
      setSelectedFactors(prevFactors => [...prevFactors, factor]);
    }
  };

  const handleCombinationSelection = (combination: string) => {
    if (selectedCombination !== combination) {
      setSelectedCombination(combination);
    }

    // remove all factors in selectedFactors array
    setSelectedFactors([]);

    if (combination === 'default') return;

    const factors = factorCombinations[combination];

    fetchAndSetFactors(factors);
  }

  useEffect(() => {
    if (selectedFactor) {
      getDataForFactor(selectedFactor);
    }
  }, [selectedFactor, categoryFilter]);

  useEffect(() => {
    // make a copy of the selected factors
    let copyOfSelectedFactors = selectedFactors.slice();

    // clear the selected factors
    setSelectedFactors([]);

    // set the factors again to fetch data with the new category filter query parameter
    fetchAndSetFactors(copyOfSelectedFactors);

  }, [categoryFilter])

  // update apiResponseCsvDownload based on the selected factors
  useEffect(() => {
    if (apiResponse && selectedFactors.length > 0) {
      // Filter apiResponse based on selectedFactors
      const filteredApiResponse = Object.keys(apiResponse)
        .filter(factor => selectedFactors.includes(factor))
        .reduce((obj, key) => {
          obj[key] = apiResponse[key];
          return obj;
        }, {} as any);
  
      // Update apiResponseCsvDownload state
      setApiResponseCsvDownload(filteredApiResponse);
    } else {
      // Reset apiResponseCsvDownload if no selected factors
      setApiResponseCsvDownload({});
    }
  }, [apiResponse, selectedFactors]);
  

  return (
    <div className="page-layout">
      <div>
        <h2>2.1 Average rank of factors compelling faculty to stay or leave</h2>
        <div>
          <div>Tips for reading the graph:</div>
          <p>
            Factors for reasons to stay or leave are grouped into themes for clarity and organization. Additionally, the top 5 most selected factors are grouped together to provide a convenient starting point for analysis. You can display up to 5 factors across themes or view all factors under one theme at a time.
          </p>
          <p>
            In the accompanying graph, the Y-axis indicates how many respondents selected a particular factor as reason to stay or leave. The X-axis shows the average rank of the importance of each factor. Factors positioned higher on the Y-axis indicate greater frequency of selection by respondents. Factors located further to the right on the X-axis are perceived, on average, as more important by the respondents.
          </p>
        </div>
      </div>
      <div className="page-layout">
        <div className="page-layout">
          {loading ? <PageLoader /> : <>
            <InstitutionN institutionN={institutionN} />
            <FilterDropdown setSelectedFilter={setCategoryFilter} />
          </>}

          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="factor-option-header">Factors</div>

              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div className="radio-checkbox-container">
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="default"
                        value="default"
                        onChange={(e) => handleCombinationSelection("default")}
                        checked={
                          selectedCombination ===
                          "default"
                        }
                      >
                      </input>
                      <label htmlFor="default">
                        No Selection
                      </label>
                    </div>
                    {Object.keys(factorCombinations).map((key: string) =>
                      <Fragment key={key}>
                        <div className="radio-container">
                          <input type="radio"
                            id={key}
                            value={key}
                            onChange={(e) => handleCombinationSelection(key)}
                            checked={
                              selectedCombination ===
                              key
                            }
                          >
                          </input>
                          <label htmlFor={key}>
                            {formatString(key)}
                          </label>
                        </div>
                        {
                          factorCombinations[key].map((factor: string) => {
                            if (key === "top_5_most_selected_factors") {
                              return null;
                            }
                            return (
                              <div key={factor} className="checkbox-factor-container">
                                <input
                                  id={factor}
                                  className="checkbox-factor-input"
                                  type="checkbox"
                                  value={factor}
                                  checked={selectedFactors.includes(factor)}
                                  onChange={() => handleFactorChange(factor, 5)}
                                />
                                <label htmlFor={factor}>
                                  {formatString(factor)}
                                </label>
                              </div>
                            )
                          })
                        }
                      </Fragment>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        {selectedFactors.length === 0 &&
          <div>
            Check the factors you'd like to see a chart for.
          </div>
        }
      </div>
      <div>
        {
          responseObject &&
          <>
            {selectedFactors.map((factor, i) =>
              <DotChart
                key={`${factor}-${categoryFilter}-${i}`}
                data={responseObject[factor]}
                selectedFilter={categoryFilter}
                factor={factor}
                institutionN={institutionN}
                maxRanking={5}
              />
            )}
            <ApiResponseToCSVConverter
              apiResponse={apiResponseCsvDownload}
              selectedFilter={categoryFilter}
              filename="2.1_Average_rank"
              section="2.1"
            />
          </>
        }
      </div>

      
    </div>
  )
}

export default AverageRank;