import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import Subsection1 from "./Subsection1";
import Subsection1b from "./Subsection1b";
import Subsection2 from "./Subsection2";
import Subsection3 from "./Subsection3";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface PreEmptiveRetentionsProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const PreEmptiveRetentions: FC<PreEmptiveRetentionsProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("Preemptive Retentions", true);
  }, [])

  useEffect(() => {
    if (subsection === "ProposedChanges") {
      toggleSection("Preemptive Retentions/Proposed Changes", true);
    }
  }, [subsection])

  return (
    <>
      <BreadcrumbNav section="PreemptiveRetentions" subsection={subsection} />

      <h1>Preemptive Retentions</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "ProposedChanges" && <Subsection1 />}
      {subsection === "ResponsesToOtherPromptsProposedChanges" && <Subsection1b />}
      {subsection === "Comments" && <Subsection2 />}
      {subsection === "AdditionalDetails" && <Subsection3 />}

      <BottomNavigation />
    </>
  )
}

export default PreEmptiveRetentions;