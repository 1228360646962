import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import {
  TransformedData,
  transformDataStackedByAnswer
} from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import StackedByAnswer from "../../charts/stackedBarChart/stackedByAnswer";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";

const Time = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [apiResponseA, setApiResponseA] = useState<NestedResponseData | null>(null);
  const [apiResponseB, setApiResponseB] = useState<NestedResponseData | null>(null);
  const [transformedResponseA, setTransformedResponseA] = useState<TransformedData[] | null>(null);
  const [transformedResponseB, setTransformedResponseB] = useState<TransformedData[] | null>(null);
  const [institutionNresponseA, setInstitutionNresponseA] = useState(0);
  const [institutionNresponseB, setInstitutionNresponseB] = useState(0);


  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getDataSectionA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section4/subsection1a/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseA(data);
        setTransformedResponseA(transformDataStackedByAnswer(data, "4.1a", selectedFilter));
        setInstitutionNresponseA(data.institution.total.total);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataSectionB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section4/subsection1b/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseB(data);
        setTransformedResponseB(transformDataStackedByAnswer(data, "4.1b", selectedFilter));
        setInstitutionNresponseB(data.institution.total.total);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataSectionA();
    getDataSectionB();

  }, [getAccessTokenSilently, selectedFilter]);


  return (
    <>
      <h2>4.1 Time spent considering leaving/spent searching</h2>

      <FilterDropdown setSelectedFilter={setSelectedFilter} />

      <h4>Time spent considering leaving</h4>
      <p>
        Prior to receiving the offer/opportunity to work elsewhere, for approximately how long were you considering leaving?
      </p>
      {!transformedResponseA && <PageLoader />}

      {transformedResponseA &&
        <>
          <InstitutionN institutionN={institutionNresponseA} />
          <StackedByAnswer
            data={transformedResponseA}
            section="4.1a"
            sequential={true}
            institutionN={institutionNresponseA}
            selectedFilter={selectedFilter}
          />
          <ApiResponseToCSVConverter
            apiResponse={apiResponseA}
            selectedFilter={selectedFilter}
            filename="4.1a_Time_spent_considering_leaving"
            section="4.1a"
          />
        </>
      }


      <h4>Time spent searching for a new position</h4>
      <p>
        Prior to receiving the offer/opportunity to work elsewhere, for approximately how long were you actively searching for a new position (e.g. submitting applications, talking to potential employers, formally
        interviewing, etc.)?
      </p>
      {!transformedResponseB && <PageLoader />}

      {transformedResponseB &&
        <>
          <InstitutionN institutionN={institutionNresponseB} />
          <StackedByAnswer
            data={transformedResponseB}
            section="4.1b"
            sequential={true}
            institutionN={institutionNresponseB}
            selectedFilter={selectedFilter}
          />
          <ApiResponseToCSVConverter
            apiResponse={apiResponseB}
            selectedFilter={selectedFilter}
            filename="4.1b_Time_spent_searching"
            section="4.1b"
          />
        </>
      }

      
    </>
  )
}

export default Time;