import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import { getLegendDomain } from "../../utils/utils";
import { TransformedData } from "../../utils/transformDataUtils";
import { getModifedCategories, Categories } from "../../utils/legendMapping/categoryLegend";
import { determineFill, getColorScale, determineTextLabelDisplay } from "../shared/colorSchemeUtils";
import { defaultXscaleBarChart, plotStyle } from "../shared/observablePlotUtils";

interface StackedByAnswerProps {
    data: TransformedData[];
    section: string;
    selectedFilter: string;
    sequential?: boolean;
}

const StackedByAnswer: FC<StackedByAnswerProps> = ({ data, section, selectedFilter, sequential }) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let chart;
        let width = 850;

        const domain = getLegendDomain(section);
        let colorScale = getColorScale(sequential, domain);

        let categories = getModifedCategories("theTransition")
        let currentDescription = categories[selectedFilter as keyof Categories];

        if (plotContainerRef.current && data) {
            plotContainerRef.current.innerHTML = "";

            for (const description of currentDescription) {
                chart = Plot.plot({
                    marks: [
                        Plot.barX(data.filter((item: any) => item.description === description), {
                            x: "value",
                            y: "type",
                            fy: "category",
                            fill: "answerChoice",
                            sort: {
                                fy: { value: "y", order: null },
                                y: { value: "y", order: null }
                            },
                            insetTop: 0.2,
                            insetBottom: 0.2,
                            stroke: "white",
                            strokeWidth: .75,
                            offset: "normalize",
                            title: (d) => determineTextLabelDisplay(d, "tooltip", "answerChoice")

                        }),
                        Plot.textX(data.filter((item: any) => item.description === description),
                            Plot.stackX({
                                text: d => determineTextLabelDisplay(d, "label", "answerChoice"),
                                x: "value",
                                y: "type",
                                fy: "category",
                                fill: d => determineFill(d, sequential, colorScale, domain),

                            })),
                        Plot.axisFy({
                            lineWidth: 8,
                            anchor: "left",
                            tickPadding: 130,
                            label: null,
                            fontWeight: "bold"

                        }),
                        Plot.axisY({
                            lineWidth: 6,
                        }),
                    ],
                    width: Math.max(width, 550),
                    marginLeft: 250,
                    title: description,
                    x: defaultXscaleBarChart,
                    y: { label: null, domain: ["Your Institution", "Cohort"] },
                    fy: {
                        domain: ["Colleagues", "Department Chair", "Dean", "Staff And Admin Support"],
                    },
                    color: colorScale,
                    style: plotStyle,
                });
                plotContainerRef.current.appendChild(chart);
            }
        }
    }, [data])

    return (
        <div ref={plotContainerRef} />
    )
}

export default StackedByAnswer;