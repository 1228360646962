import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TextResponse } from "../../models/responseData";
import { cleanString } from "../../utils/formatString";
import { determineResponseTypeTextResponse } from "../../utils/determineResponseType";


const TextBasedResponse = () => {
  const [transformedResponse, setTransformedResponse] = useState<TextResponse | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year, institution } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section8/subsection5/?report_year=${year}&group_by=exit_status`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTextResponse(data)) {
        setTransformedResponse(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently]);
  return (
    <>
      <h2>8.5 Additional comments about decision to stay or accept outside offer (Open-text Response)</h2>
      <h3>What additional comments do departures and retentions have about their decision to stay or to accept an outside offer?</h3>

      <div className="grid">
        <div className="grid-row-title">
          <em>Prompt:</em>
        </div>
        <div>
          Use the space below to provide any additional comments related to your decision to stay at <span className="red-variable-name">{institution}</span> or to accept an outside offer.
        </div>
        <div className="grid-row-title">
          <em>Paths:</em>
        </div>
        <div>
          Departures and retentions
        </div>
      </div>
      <h4>Departures</h4>
      {transformedResponse && transformedResponse.institution.departure &&
        <>
          {transformedResponse.institution.departure.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.departure.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }
      <h4>Retentions</h4>
      {transformedResponse && transformedResponse.institution.retention &&
        <>
          {transformedResponse.institution.retention.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.retention.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }
      
    </>
  )
}

export default TextBasedResponse;