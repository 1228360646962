import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TripleNestedResponseData } from "../../models/responseData";
import {
  TransformedData,
  transformTripleNestedDataStackedByAnswer
} from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import StackedByAnswer from "../../charts/stackedBarChart/stackedByAnswerNested";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeTripleNestedResponseData } from "../../utils/determineResponseType";


const Treatment = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [apiResponseA, setApiResponseA] = useState<TripleNestedResponseData | null>(null);
  const [apiResponseAstatistics, setApiResponseAstatistics] = useState<TripleNestedResponseData | null>(null);
  const [transformedResponse, setTransformedResponse] = useState<TransformedData[] | null>(null);
  const [institutionN, setInstitutionN] = useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section7/subsection1/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTripleNestedResponseData(data)) {
        setApiResponseA(data);
        setTransformedResponse(transformTripleNestedDataStackedByAnswer(data, selectedFilter, "7.1"));
        setInstitutionN(data.total.institution.total as unknown as number);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataStatistics = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section7/subsection1/?report_year=${year}&group_by=${selectedFilter}&statistics=True`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTripleNestedResponseData(data)) {
        setApiResponseAstatistics(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
    getDataStatistics();
  }, [getAccessTokenSilently, selectedFilter]);

  return (
    <>
      <h2>7.1 Treatment of faculty after departure intentions are known</h2>
      <p>
        How were you treated by the following people at your institution after your decision to accept the outside offer (compared to how you were treated beforehand)?
      </p>
      <p>
        Note: This question is only asked of departures.
      </p>

      {!transformedResponse && <PageLoader />}

      {transformedResponse &&
        <>
          <InstitutionN institutionN={institutionN} />
          <FilterDropdown setSelectedFilter={setSelectedFilter} />
          <StackedByAnswer
            data={transformedResponse}
            section="7.1"
            selectedFilter={selectedFilter}
            sequential={true}
          />
          {
            apiResponseAstatistics &&
            <ApiResponseToCSVConverter
              apiResponse={apiResponseA}
              apiResponseB={apiResponseAstatistics}
              selectedFilter={selectedFilter}
              filename="7.1_Treatment_of_faculty"
              section="7.1"
            />
          }
        </>
      }

      
    </>
  )
}

export default Treatment;