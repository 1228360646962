import React, { Fragment, useState, useEffect, useRef } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { convertToPercentString, formatLabels } from "../../utils/utils";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";


const SubSection2 = () => {
  const [responseA, setResponseA] = useState<NestedResponseData | null>(null);
  const [responseB, setResponseB] = useState<NestedResponseData | null>(null);
  const [institutionNresponseA, setInstitutionNresponseA] = useState(0);
  const [institutionNresponseB, setInstitutionNresponseB] = useState(0);
  const tableRefA = useRef<HTMLTableElement>(null);
  const tableRefB = useRef<HTMLTableElement>(null);
  const [tableA, setTableA] = useState<HTMLTableElement | null>(null);
  const [tableB, setTableB] = useState<HTMLTableElement | null>(null);
  const [loadingA, setLoadingA] = useState(true);
  const [loadingB, setLoadingB] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const { reportType, year } = useParams();
  const location = useLocation();
  const splitPath = location.pathname.split("/");
  const path = splitPath[1] + "/" + splitPath[2];

  const statuses = ["lower", "equal", "higher"];
  const outsideOfferTitles = ["no_administrative_title", "chair_or_head", "director", "dean", "provost", "other"];

  useEffect(() => {
    const getDataA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection2a/?report_year=${year}&grouped_data=true`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseA(data);
        setInstitutionNresponseA(data.institution.total.total);
        setLoadingA(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection2b/?report_year=${year}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseB(data);
        setInstitutionNresponseB(data.institution.total.total);
        setLoadingB(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataA();
    getDataB();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (tableRefA.current) {
      setTableA(tableRefA.current);
    }
  }, [responseA]);

  useEffect(() => {
    if (tableRefB.current) {
      setTableB(tableRefB.current);
    }
  }, [responseB]);

  return (
    <>
      <h2>6.2 Rank, tenure and title: Pre-offer vs. Offer</h2>
      <p>
        What academic rank/tenure did you have at your institution at the time you received an outside offer? <br />
        What academic rank/tenure was offered to you in the outside offer you received? <br />
        Which of the following administrative titles, if any, were included in the outside offer?
      </p>
      <p className="disclaimer-note">
        Please refer to the Appendix section <Link id="link-text" to={`/${path}/${reportType}/${year}/Appendix/NegotiationTerms-RankTenureTitle`}>(Rank, tenure, and title: Pre-offer vs. Offer)</Link> for the full table.
      </p>
      <div className="page-layout">
        <div>
          <h4>
            Comparison of rank/tenure status at your institution vs. in the outside offer
          </h4>

          {!responseA && <PageLoader />}
          {responseA &&
            <>
              <InstitutionN institutionN={institutionNresponseA} />
              <table ref={tableRefA} className="preoffer-offer-table">
                <thead>
                  <tr>
                    <th colSpan={2}></th>
                    <th colSpan={7} className="offer-type-title">In Outside Offer</th>
                  </tr>
                  <tr className="column-headers">
                    <th colSpan={2} className="row-split offer-type-title">At Time of Offer</th>
                    <th className="institution-cohort-header">More Junior Rank and/or Tenure</th>
                    <th className="institution-cohort-header">Same Rank and/or Tenure</th>
                    <th className="institution-cohort-header">More Senior Rank and/or Tenure</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(responseA).map((population, i) =>
                    <Fragment key={`${population} - ${i}`}>
                      <tr>
                        <th rowSpan={5} className="row-split-type row-header no-alt-color">{formatLabels(population)}</th>
                        <th className="row-header">Assistant, pre-tenure</th>
                        {statuses.map((status, i) => {
                          let current = responseA[population].assistant_pre_tenure;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[status], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Associate, pre-tenure</th>
                        {statuses.map((status, i) => {
                          let current = responseA[population].associate_pre_tenure;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[status], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Associate, tenured</th>
                        {statuses.map((status, i) => {
                          let current = responseA[population].associate_tenured;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[status], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Full Professor, tenured</th>
                        {statuses.map((status, i) => {
                          let current = responseA[population].full_professor_tenured;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[status], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr className="last-row-split">
                        <th className="row-header">Endowed Chair Professor</th>
                        {statuses.map((status, i) => {
                          let current = responseA[population].endowed_chair_professor;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[status], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
              <TableToCSVConverter
                table={tableA}
                filename="6.2a_Rank,_tenure_and_title"
                loading={loadingA}
                setLoading={setLoadingA}
                section="6.2"
              />
            </>
          }
        </div>

        <div>
          <h4>
            Administrative appointments included in the outside offers, by faculty rank/tenure status at the time of offer
          </h4>

          {!responseB && <PageLoader />}
          {responseB &&
            <>
              <InstitutionN institutionN={institutionNresponseB} />
              <table ref={tableRefB} className="preoffer-offer-table">
                <thead>
                  <tr>
                    <th colSpan={2}></th>
                    <th colSpan={7} className="offer-type-title">In Outside Offer</th>
                  </tr>
                  <tr className="column-headers">
                    <th colSpan={2} className="row-split offer-type-title">At Time of Offer</th>
                    <th className="title-header">I was not offered an administrative title</th>
                    <th className="title-header">Department Chair/Head, Associate or Assistant Chair/Head</th>
                    <th className="title-header">Center or Program Director</th>
                    <th className="title-header">Dean, Associate Dean, Assistant Dean, Vice Dean, Division Chief</th>
                    <th className="title-header">Provost, Associate Provost, Assistant Provost, Vice Provost</th>
                    <th className="title-header">Other Administrative Title</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(responseB).map((population, i) =>
                    <Fragment key={`${population} - ${i}`}>
                      <tr>
                        <th rowSpan={5} className="row-split-type row-header no-alt-color">{formatLabels(population)}</th>
                        <th className="row-header">Assistant, pre-tenure</th>
                        {outsideOfferTitles.map((title, i) => {
                          let current = responseB[population].assistant_pre_tenure;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[title], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Associate, pre-tenure</th>
                        {outsideOfferTitles.map((title, i) => {
                          let current = responseB[population].associate_pre_tenure;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[title], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Associate, tenured</th>
                        {outsideOfferTitles.map((title, i) => {
                          let current = responseB[population].associate_tenured;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[title], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Full Professor, tenured</th>
                        {outsideOfferTitles.map((title, i) => {
                          let current = responseB[population].full_professor_tenured;
                          return (
                            <td key={i}> 
                              {convertToPercentString(current[title], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr className="last-row-split">
                        <th className="row-header">Endowed Chair Professor</th>
                        {outsideOfferTitles.map((title, i) => {
                          let current = responseB[population].endowed_chair_professor;
                          return (
                            <td key={i}>
                              {convertToPercentString(current[title], current.total)}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
              <TableToCSVConverter
                table={tableB}
                filename="6.2b_Rank,_tenure_and_title"
                loading={loadingB}
                setLoading={setLoadingB}
                section="6.2"
              />
            </>
          }
        </div>
      </div>
      
    </>
  )
}

export default SubSection2;