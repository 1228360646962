import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import Subsection1 from "./Subsection1";
import Subsection2 from "./Subsection2";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface TheTransitionProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const TheTransition:FC<TheTransitionProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("The Transition", true);
  }, [])

  return (
    <>
      <BreadcrumbNav section="TheTransition" subsection={subsection} />

      <h1>The Transition</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "Treatment" && <Subsection1 />}
      {subsection === "TextBasedResponse" && <Subsection2 />}

      <BottomNavigation />
    </>
  )
}

export default TheTransition;