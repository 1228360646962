import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import { transformDataSlopeChart, TransformedDataSlopeGraph } from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import SlopeChart from "../../charts/slopeChart/SlopeChart";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";

interface SlopeTransformedData {
  type: string;
  Category: string;
  "Original Salary": number;
  "% Change to Offer": number | undefined;
  "% Change to Counteroffer"?: number;
}

const combineTransformedData = (dataA: TransformedDataSlopeGraph[], dataB: TransformedDataSlopeGraph[]) => {

  const transformedData: SlopeTransformedData[] = [];
  for (let i = 0; i < Math.min(dataA.length, dataB.length); i++) {
    const combinedObject: SlopeTransformedData = {
      type: dataA[i].type,
      Category: dataA[i].category,
      "Original Salary": 0,
      "% Change to Offer": dataA[i].median_percent_change,
    }

    if (dataB[i].median_percent_change) {
      combinedObject["% Change to Counteroffer"] = dataB[i].median_percent_change;
    } else {
      combinedObject["% Change to Counteroffer"] = dataA[i].median_percent_change - dataB[i].median_percent_change;

    }
    transformedData.push(combinedObject);
  }
  return transformedData;
}

const SubSection3 = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [apiResponseA, setApiResponseA] = useState<NestedResponseData | null>(null);
  const [apiResponseB, setApiResponseB] = useState<NestedResponseData | null>(null);
  const [apiResponseC, setApiResponseC] = useState<NestedResponseData | null>(null);
  const [transformedResponseA, setTransformedResponseA] = useState<TransformedDataSlopeGraph[]>()
  const [transformedResponseC, setTransformedResponseC] = useState<TransformedDataSlopeGraph[]>()
  const [institutionNresponseA, setInstitutionNresponseA] = useState(0);
  const [institutionNresponseB, setInstitutionNresponseB] = useState(0);
  const [institutionNresponseC, setInstitutionNresponseC] = useState(0);
  const [combinedTransformedData, setcombinedTransformedData] = useState<SlopeTransformedData[]>()

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getDataA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection3a/?report_year=${year}&group_by=${selectedFilter}&statistics=True`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseA(data);
        setTransformedResponseA(transformDataSlopeChart(data, selectedFilter));
        setInstitutionNresponseA(data.institution.total.count)
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection3b/?report_year=${year}&group_by=${selectedFilter}&statistics=True`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseB(data);
        setInstitutionNresponseB(data.institution.total.count);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataC = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection3c/?report_year=${year}&group_by=${selectedFilter}&statistics=True`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseC(data);
        setTransformedResponseC(transformDataSlopeChart(data, selectedFilter));

        setInstitutionNresponseC(data.institution.total.count);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataA();
    getDataB();
    getDataC();

  }, [getAccessTokenSilently, selectedFilter]);


  useEffect(() => {
    if (transformedResponseA && transformedResponseC) {
      setcombinedTransformedData(combineTransformedData(transformedResponseA, transformedResponseC))
    }
  }, [transformedResponseA, transformedResponseC])

  return (
    <>
      <h2>6.3 Compensation: Pre-offer vs. Offer vs. Counteroffer</h2>
      <h3>Median Percent Changes to Salary</h3>
      <div>
        <div>Tips for reading the graph:</div>
        <p>
          The line graph illustrates the median percent change in salary reported by respondents for both outside offers and counteroffers, in comparison to their original salary.
        </p>
      </div>

      <FilterDropdown setSelectedFilter={setSelectedFilter} />

      {!combinedTransformedData && <PageLoader />}

      {combinedTransformedData &&
        <SlopeChart
          data={combinedTransformedData.filter((item) => item.type === "Your Institution")}
          title="Your Institution"
          selectedFilter={selectedFilter}
        />
      }

      {combinedTransformedData &&
        <SlopeChart
          data={combinedTransformedData.filter((item) => item.type === "Cohort")}
          title="Cohort"
          selectedFilter={selectedFilter}
        />
      }
      <br />

      <h3>Data from related questions</h3>
      <h4>1) Difference between base salary at your institution (i.e., original salary) and in your outside offer [offer - original]</h4>
      {!apiResponseA && <PageLoader />}
      {apiResponseA &&
        <>
          <InstitutionN institutionN={institutionNresponseA} />
          <ApiResponseToCSVConverter
            apiResponse={apiResponseA}
            selectedFilter={selectedFilter}
            filename="6.3a_Compensation:_Pre-offer_vs._Offer_vs._Counteroffer"
            section="6.3"
          />
        </>
      }

      <h4>2) Difference between base salary in your outside offer and in your counteroffer (if any) [counter - offer]</h4>
      {!apiResponseB && <PageLoader />}
      {apiResponseB &&
        <>
          <InstitutionN institutionN={institutionNresponseB} />
          <ApiResponseToCSVConverter
            apiResponse={apiResponseB}
            selectedFilter={selectedFilter}
            filename="6.3b_Compensation:_Pre-offer_vs._Offer_vs._Counteroffer"
            section="6.3"
          />
        </>
      }

      <h4>3) Difference between base salary at your institution (i.e., original salary) and in your counteroffer [counter - original]</h4>
      {!apiResponseC && <PageLoader />}
      {apiResponseC &&
        <>
          <InstitutionN institutionN={institutionNresponseC} />
          <ApiResponseToCSVConverter
            apiResponse={apiResponseC}
            selectedFilter={selectedFilter}
            filename="6.3c_Compensation:_Pre-offer_vs._Offer_vs._Counteroffer"
            section="6.3"
          />
        </>
      }
    </>
  )
}

export default SubSection3;