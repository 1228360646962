import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import { TransformedData } from "../../utils/transformDataUtils";
import { getModifedCategories, Categories } from "../../utils/legendMapping/categoryLegend";
import { plotStyle, defaultXscaleBarChart } from "../shared/observablePlotUtils";

interface BarChartProps {
    data: TransformedData[];
    selectedFilter: string;
    departureType: string;
}

const BarChart: FC<BarChartProps> = ({ data, selectedFilter, departureType }) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let chart;

        if (plotContainerRef.current && data) {
            plotContainerRef.current.innerHTML = "";
            const categories = getModifedCategories(departureType);
            let currentCategories = categories[selectedFilter as keyof Categories];

            for (const category of currentCategories) {
                chart = Plot.plot({
                    marks: [
                        Plot.barX(data.filter((item: any) => {
                            return item.category === category
                        }), {
                            x: "value",
                            y: "description",
                            fx: "type",
                            fill: "#565656",
                            sort: {
                                fx: { value: "x", order: null },
                                x: { value: "x", order: null },
                                y: { value: "y", order: null },
                            },
                            insetTop: 0.2,
                            insetBottom: 0.2
                        }),
                        Plot.text(data.filter((item: any) => {
                            return item.category === category
                        }), {
                            text: d => `${Math.round(d.value * 10000) / 100}%`,
                            x: "value",
                            y: "description",
                            fx: "type",
                            dx: 18,
                            fill: "#565656"
                        }),
                    ],
                    x: {
                        ...defaultXscaleBarChart,
                        nice: true
                    },
                    y: { label: null },
                    fx: { label: null },
                    title: category,
                    width: 900,
                    marginLeft: 270,
                    style: plotStyle,
                });
                plotContainerRef.current.appendChild(chart);
            }
        }
    }, [data, selectedFilter])

    return (
        <div ref={plotContainerRef} />
    )
}

export default BarChart;