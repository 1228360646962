import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import AverageRank from "./Subsection1";
import PrimaryAndSecondaryFactors from "./Subsection2";
import TextBasedResponses from "./Subsection3";
import ResponsesToOtherPrompts from "./Subsection4";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface WeighingTheFactorsProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const WeighingTheFactors: FC<WeighingTheFactorsProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("Weighing The Factors", true);
  }, [])

  return (
    <>
      <BreadcrumbNav section="WeighingTheFactors" subsection={subsection} />

      <h1>Weighing The Factors</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "AverageRank" && <AverageRank />}
      {subsection === "TopFactors" && <PrimaryAndSecondaryFactors />}
      {subsection === "Comments" && <TextBasedResponses />}
      {subsection === "ResponsesToOtherPrompts" && <ResponsesToOtherPrompts />}

      <BottomNavigation />
    </>
  )
}

export default WeighingTheFactors;