import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import MaritalStatus from "./Subsection1";
import Career from "./Subsection2";
import EmploymentStatus from "./Subsection3";
import TypeOfPositions from "./Subsection4";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import RoleCareerResponsesToOtherPrompts from "./Subsection2b";
import TypesOfPositionsResponsesToOtherPrompts from "./Subsection4b";
import BottomNavigation from "../../navigation/BottomNavigation";

interface SpousesPartnersProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const SpousesPartners: FC<SpousesPartnersProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("Spouses & Partners", true);
  }, [])

  useEffect(() => {
    if (subsection === "Career") {
      toggleSection("Spouses & Partners/Career", true);
    }

    if (subsection === "Positions") {
      toggleSection("Spouses & Partners/Positions", true);
    }
  }, [subsection])

  return (
    <>
      <BreadcrumbNav section="Spouses&Partners" subsection={subsection} />

      <h1>Spouses & Partners</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "MaritalStatus" && <MaritalStatus />}
      {subsection === "Career" && <Career />}
      {subsection === "ResponsesToOtherPromptsCareer" && <RoleCareerResponsesToOtherPrompts />}
      {subsection === "EmploymentStatus" && <EmploymentStatus />}
      {subsection === "Positions" && <TypeOfPositions />}
      {subsection === "ResponsesToOtherPromptsPositions" && <TypesOfPositionsResponsesToOtherPrompts />}

      <BottomNavigation />
    </>
  )
}

export default SpousesPartners;