import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import FirstNotification from "./Subsection1";
import Seriousness from "./Subsection2";
import CounterofferNotification from "./Subsection3";
import TextBasedResponses from "./Subsection4";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface NegotiationProcessProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const NegotiationProcess: FC<NegotiationProcessProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("The Negotiation Process", true);
  }, [])

  return (
    <>
      <BreadcrumbNav section="NegotiationProcess" subsection={subsection} />

      <h1>The Negotiation Process</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "FirstNotification" && <FirstNotification />}
      {subsection === "Seriousness" && <Seriousness />}
      {subsection === "CounterofferNotification" && <CounterofferNotification />}
      {subsection === "YourInstitutionsHandling" && <TextBasedResponses />}

      <BottomNavigation />
    </>
  )
}

export default NegotiationProcess;