import { format } from "d3";


export const plotStyle = {
    fontSize: "12px",
    overflow: "visible"
}

export const defaultXscaleBarChart = {
    line: true,
    label: null,
    tickFormat: format(".0%"),
    // nice: true,
}