import { Categories, getModifedCategories } from "../../utils/legendMapping/categoryLegend";
export interface LegendData {
    label: string;
    description: string;
    color: string;
    symbol: string;
}

export interface LegendConfig {
    labels: string[];
    categories: string[];
    labelColors: Record<string, string>;
    symbols: string[];
}

export const generateLegendConfig = (filter: string) => {
    let averageRankCategories = getModifedCategories("averageRank");
    return {
        labels: ["Your Institution", "Cohort"],
        categories: averageRankCategories[filter as keyof Categories], 
        labelColors: {
            Institution: "#185C90",
            Cohort: "#A5BBC3",
        },
        symbols: ["cross", "diamond", "circle", "square", "star", "triangle"]
    };
}

export const generateLegendData = (config: LegendConfig): LegendData[] => {
    const legendData: LegendData[] = [];

    config.labels.forEach((label) => {
        config.categories.forEach((category, index) => {
            const description = category.replace(/_/g, " "); // Replace underscores with spaces
            const color = config.labelColors[label] || "#185C90"; // Default color if not specified
            const symbol = config.symbols[index] || "circle"; // Default symbol if not specified

            legendData.push({
                label,
                description,
                color,
                symbol,
            });
        });
    });

    return legendData;
}

// Helper function to get the SVG markup for a symbol
export const getSymbolMarkup = (data: any) => {
    switch (data.symbol) {
        case "circle":
            return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="${data.color}" fill-opacity="1" stroke="${data.color}" stroke-opacity="1" stroke-width="1.5"><path d="M4.5,0A4.5,4.5,0,1,1,-4.5,0A4.5,4.5,0,1,1,4.5,0"></path></svg>`;
        case "diamond":
            return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="${data.color}" fill-opacity="1" stroke="${data.color}" stroke-opacity="1" stroke-width="1.5"><path d="M0,-7.423L4.285,0L0,7.423L-4.285,0Z"></path></svg>`;
        case "cross":
            return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="${data.color}" fill-opacity="1" stroke="${data.color}" stroke-opacity="1" stroke-width="1.5"><path d="M-5.35,-1.783L-1.783,-1.783L-1.783,-5.35L1.783,-5.35L1.783,-1.783L5.35,-1.783L5.35,1.783L1.783,1.783L1.783,5.35L-1.783,5.35L-1.783,1.783L-5.35,1.783Z"></path></svg>`;
        case "square":
            return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="${data.color}" fill-opacity="1" stroke="${data.color}" stroke-opacity="1" stroke-width="1.5"><path d="M-3.988,-3.988h7.976v7.976h-7.976Z"></path></svg>`;
        case "star":
            return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="${data.color}" fill-opacity="1" stroke="${data.color}" stroke-opacity="1" stroke-width="1.5"><path d="M0,-7.528L1.69,-2.326L7.16,-2.326L2.735,0.889L4.425,6.09L0,2.875L-4.425,6.09L-2.735,0.889L-7.16,-2.326L-1.69,-2.326Z"></path></svg>`;
        case "triangle":
            return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="${data.color}" fill-opacity="1" stroke="${data.color}" stroke-opacity="1" stroke-width="1.5"><path d="M0,-6.928L6,3.464H-6Z"></path></svg>`;
            
        default:
            return "";
    }
};

export const getSymbolMarkupWithStroke = (data: any) => {
    if (!data.fillColor) {
        return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="none"  stroke="${data.strokeColor}" stroke-opacity="1" stroke-width="1.5"><path d="M4.5,0A4.5,4.5,0,1,1,-4.5,0A4.5,4.5,0,1,1,4.5,0"></path></svg>`;
    }
    return `<svg viewBox="-8 -8 16 16" width="15" height="15" fill="${data.fillColor}" fill-opacity="1" stroke="${data.strokeColor}" stroke-opacity="1" stroke-width="1.5"><path d="M4.5,0A4.5,4.5,0,1,1,-4.5,0A4.5,4.5,0,1,1,4.5,0"></path></svg>`;
}