import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { getModifedCategories, Categories } from "../../utils/legendMapping/categoryLegend";

interface SlopeChartProps {
    data: any[];
    title: string;
    selectedFilter: string;
}

const SlopeChart: FC<SlopeChartProps> = ({ data, title, selectedFilter }) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    let max = 0;

    // calculate the max value for the y domain
    data.forEach((object) =>
        Object.values(object).forEach((value) => {
            if (typeof value === "number" && value > max) {
                max = value;
            }
        }))

    useEffect(() => {

        let chart;
        let width = 1000;
        let currentCategories =
            getModifedCategories("totalFirst")[selectedFilter as keyof Categories];

        if (plotContainerRef.current && data) {
            plotContainerRef.current.innerHTML = "";
            chart = Plot.plot({
                x: {
                    axis: "top",
                    grid: true,
                    tickSize: 0,
                    reverse: true,
                },
                y: {
                    nice: true,
                    axis: "right",
                    tickSize: 0,
                    domain: [0, max],
                    tickFormat: d3.format("0.0%")
                },
                marginBottom: 10,
                marks: [
                    // connecting line
                    Plot.link(data, {
                        x1: () => "Original Salary",
                        x2: () => "% Change to Offer",
                        y1: "Original Salary",
                        y2: "% Change to Offer",
                        strokeWidth: 0.5,
                        filter: (d) => {
                            const offerValue = d["% Change to Offer"];
                            return offerValue > 0;
                        }
                    }),

                    // connecting line
                    Plot.link(data, {
                        filter: (item) => {
                            const counterofferValue = item["% Change to Counteroffer"];
                            return counterofferValue !== item["% Change to Offer"];
                        },
                        x1: () => "% Change to Offer",
                        x2: () => "% Change to Counteroffer",
                        y1: "% Change to Offer",
                        y2: "% Change to Counteroffer",
                        strokeWidth: 0.5,
                    }),


                    // symbol mark: % Change to Offer 
                    Plot.dot(data, {
                        filter: (item) => {
                            const offerValue = item["% Change to Offer"];
                            const counterofferValue = item["% Change to Counteroffer"];
                            return offerValue > 0 && counterofferValue === offerValue;
                        },
                        x: () => "% Change to Offer",
                        y: "% Change to Offer",
                        symbol: "Category",
                        stroke: "Category",
                        r: 10,
                        strokeWidth: 2,
                    }),

                    // symbol mark: % Change to Counteroffer
                    Plot.dot(data, {
                        filter: (item) => {
                            const counterofferValue = item["% Change to Counteroffer"];
                            return counterofferValue !== item["% Change to Offer"];
                        },
                        x: () => "% Change to Counteroffer",
                        y: "% Change to Counteroffer",
                        symbol: "Category",
                        stroke: "Category",
                        r: 10,
                        strokeWidth: 2
                    }),
                ],
                grid: true,
                title: title,
                height: 500,
                width: width,
                symbol: {
                    legend: true,
                    domain: currentCategories,
                },
                color: {
                    domain: currentCategories
                },
                style: {
                    fontSize: "12px"
                },
            })
            plotContainerRef.current.appendChild(chart);
        }
    }, [data])

    return (
        <div ref={plotContainerRef} />
    )
}

export default SlopeChart;