import "../App.css";
import { FC, useEffect, useState } from "react";
import Sidebar from "../navigation/Sidebar";
import About from "./About";
import TheAnalyticSample from "../sections/section1/TheAnalyticSample";
import WeighingTheFactors from "../sections/section2/WeighingTheFactors";
import SpousesPartners from "../sections/section3/SpousesPartners";
import Search from "../sections/section4/Search";
import NegotiationProcess from "../sections/section5/TheNegotiationProcess";
import NegotiationTerms from "../sections/section6/TheNegotiationTerms";
import TheTransition from "../sections/section7/TheTransition";
import OverallImpressions from "../sections/section8/OverallImpressions";
import PreEmptiveRetentions from "../sections/section9/Pre-emptiveRetentions";
import Appendix from "../sections/section10/Appendix";
import { Container, Grid, CssBaseline } from "@mui/material";
import { Route, Routes, useLocation, Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { ApplicableYear } from "../models/responseData";


interface SectionState {
  [section: string]: boolean;
}

interface QuantitativeReportProps {
  applicableYearsList: ApplicableYear[] | null
}

const QuantitativeReport: FC<QuantitativeReportProps> = ({ applicableYearsList }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { institution, year } = useParams();

  useEffect(() => {
    // navigate home if user changes url param to invalid year
    if (applicableYearsList && year) {
      if (!applicableYearsList?.map((yearItem) => yearItem.year).includes(Number(year))) {
        navigate('/');
      }
    }
  }, [year, applicableYearsList])

  const [sectionState, setSectionState] = useState<SectionState>({
    "The Analytic Sample": false,
    "Weighing The Factors": false,
    "Spouses & Partners": false,
    "Spouses & Partners/Career": false,
    "Spouses & Partners/Positions": false,
    "The Search": false,
    "Search/Initial Contact": false,
    "The Negotiation Process": false,
    "The Negotiation Terms": false,
    "Negotiation Terms/Permissible Use Startup Fund": false,
    "Negotiation Terms/Counter Offers Sought Received": false,
    "Negotiation Terms/Proposed Changes": false,
    "The Transition": false,
    "Overall Impressions": false,
    "Overall Impressions/Top Change": false,
    "Preemptive Retentions": false,
    "Preemptive Retentions/Proposed Changes": false,
    "Appendix": false
  });

  const toggleSection = (section: string, open?: boolean) => {
    if (open) {
      setSectionState((prevState) => ({
        ...prevState,
        [section]: true
      }))
    } else {
      setSectionState((prevState) => ({
        ...prevState,
        [section]: !prevState[section]
      }))
    }
  }

  return (
    <div>
      <>
        <CssBaseline />
        <Grid container>
          {location.pathname !== "/" &&
            <Grid item>
              <Sidebar
                report={"quantitativeReport"}
                sectionState={sectionState}
                toggleSection={toggleSection}
              />
            </Grid>
          }
          <Grid item xs={12} sm={location.pathname === "/" ? 12 : 9} md={location.pathname === "/" ? 12 : 8}>
            <Container>
              <main>
                {institution && <Header institutionName={institution} />}
                <Routes>
                  <Route path="/" element={<Navigate to="AboutThisReport" />} />
                  <Route path="AboutThisReport" element={<About />} />
                  <Route
                    path="TheAnalyticSample/:subsection"
                    element={<TheAnalyticSample toggleSection={toggleSection} />}
                  />
                  <Route
                    path="WeighingTheFactors/:subsection"
                    element={<WeighingTheFactors toggleSection={toggleSection} />}
                  />
                  <Route
                    path="/Spouses&Partners/:subsection"
                    element={<SpousesPartners toggleSection={toggleSection} />}
                  />
                  <Route
                    path="/Search/:subsection"
                    element={<Search toggleSection={toggleSection} />}
                  />
                  <Route
                    path="/NegotiationProcess/:subsection"
                    element={<NegotiationProcess toggleSection={toggleSection} />}
                  />
                  <Route
                    path="/NegotiationTerms/:subsection"
                    element={<NegotiationTerms toggleSection={toggleSection} />}
                  />
                  <Route
                    path="/TheTransition/:subsection"
                    element={<TheTransition toggleSection={toggleSection} />}
                  />
                  <Route
                    path="/OverallImpressions/:subsection"
                    element={<OverallImpressions toggleSection={toggleSection} />}
                  />
                  <Route
                    path="/PreemptiveRetentions/:subsection"
                    element={<PreEmptiveRetentions toggleSection={toggleSection} />}
                  />
                  <Route path="/Appendix" element={<Appendix toggleSection={toggleSection} />} />
                  <Route path="/Appendix/:subsection" element={<Appendix toggleSection={toggleSection} />} />
                </Routes>
              </main>
            </Container>
          </Grid>
        </Grid>
      </>
    </div>
  );
}

export default QuantitativeReport;
