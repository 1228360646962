import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import {
    generateLegendConfig,
    generateLegendData,
    getSymbolMarkup,
} from "../shared/symbolColorLegendUtils";
import { INSUFFICIENT_N } from "../../utils/constants";
import { plotStyle } from "../shared/observablePlotUtils";
import { formatString } from "../../utils/formatString";

interface DotChartProps {
    data: any[];
    selectedFilter: string;
    factor: string;
    institutionN: number;
    maxRanking: number;
}

const DotChart: FC<DotChartProps> = ({ data, selectedFilter, factor, institutionN, maxRanking }) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    if (data && institutionN < INSUFFICIENT_N) {
        data = data.filter(item => item.type === "Cohort");
    }

    useEffect(() => {
        let chart;
        const legendConfig = generateLegendConfig(selectedFilter);

        if (maxRanking && plotContainerRef.current && data && data.length > 0) {
            plotContainerRef.current.innerHTML = "";
            chart = Plot.plot({
                marks: [
                    // create the x and y axis
                    // xQuandrantAxis,
                    Plot.ruleX([maxRanking], { stroke: "black" }),
                    Plot.ruleY([0]),

                    // create the 4 quadrant grid
                    Plot.ruleX(
                        [Math.ceil(maxRanking / 2)],
                        { stroke: "lightgrey" }
                    ),
                    Plot.ruleY([0.5], { stroke: "lightgrey" }),

                    // plot the data
                    Plot.dot(data, {
                        x: "avg_rank",
                        y: "frequency",
                        fx: "reason",
                        fill: d => d.type === "Cohort" ? "#A5BBC3" : "#185C90",
                        symbol: d => {
                            let index = legendConfig.categories.indexOf(d.category)
                            return legendConfig.symbols[index];
                        },
                        stroke: d => d.type === "Cohort" ? "#185C90" : "#A5BBC3",
                        strokeWidth: .5,
                    }),
                ],
                x: {
                    domain: [maxRanking, 1], // reverse graph
                    ticks: [],
                    label: "Most important",
                    labelArrow: "right",
                },
                y: {
                    label: "Frequency of factor selection (%)",
                    labelAnchor: "top",
                    domain: [0, 1],
                    ticks: [0, .5, 1],
                    tickFormat: d3.format(".0%"),
                },
                fx: {
                    label: null,
                    tickPadding: 2
                },
                title: formatString(factor),
                style: plotStyle,
            })

            const legend = d3.select(plotContainerRef.current).append("div").style("marginTop", "20px");

            const resultLegendData = generateLegendData(legendConfig);

            const institutionLegend = legend
                .append("div")
                .style("display", "flex")
                .html("<strong>Your Institution</strong>");

            const cohortLegend = legend
                .append("div")
                .style("display", "flex")
                .html("<strong>Cohort</strong>");

            institutionLegend
                .selectAll("div")
                .data(resultLegendData.filter(d => d.label.includes("Institution")))
                .enter()
                .append("div")
                .style("display", "flex")
                .style("align-items", "center")
                .style("margin-right", "20px")
                .style("margin-left", "20px")
                .html(d => `
                        ${getSymbolMarkup(d)}
                        <span style="margin-left: 5px;">${d.description === "Overall" ? "Total" : d.description}</span>
                    `);

            cohortLegend
                .selectAll("div")
                .data(resultLegendData.filter(d => d.label.includes("Cohort")))
                .enter()
                .append("div")
                .style("display", "flex")
                .style("align-items", "center")
                .style("margin-right", "20px")
                .style("margin-left", "20px")
                .html(d => `
                        ${getSymbolMarkup(d)}
                        <span style="margin-left: 5px;">${d.description === "Overall" ? "Total" : d.description}</span>
                    `);
            plotContainerRef.current.appendChild(chart);
        }
    }, [data, selectedFilter, factor, maxRanking])

    return (
        <div ref={plotContainerRef} />
    )
}

export default DotChart;