import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import {
  TransformedData,
  transformDataStackedByAnswer
} from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import StackedByAnswer from "../../charts/stackedBarChart/stackedByAnswer";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";

const Seriousness = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [apiResponseAstatistics, setApiResponseAstatistics] = useState<NestedResponseData | null>(null);
  const [apiResponseA, setApiResponseA] = useState<NestedResponseData | null>(null);
  const [transformedResponseA, setTransformedResponseA] = useState<TransformedData[] | null>(null);

  const [apiResponseBstatistics, setApiResponseBstatistics] = useState<NestedResponseData | null>(null);
  const [apiResponseB, setApiResponseB] = useState<NestedResponseData | null>(null);
  const [transformedResponseB, setTransformedResponseB] = useState<TransformedData[] | null>(null);
  const [institutionNresponseA, setInstitutionNresponseA] = useState(0);
  const [institutionNresponseB, setInstitutionNresponseB] = useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getDataA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section5/subsection2a/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseA(data);
        setTransformedResponseA(transformDataStackedByAnswer(data, "5.2a", selectedFilter));
        setInstitutionNresponseA(data.institution.total.total);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section5/subsection2b/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseB(data);
        setTransformedResponseB(transformDataStackedByAnswer(data, "5.2b", selectedFilter));
        setInstitutionNresponseB(data.institution.total.total);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataA();
    getDataB();
  }, [getAccessTokenSilently, selectedFilter]);

  useEffect(() => {
    const getDataAstatistics = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section5/subsection2a/?report_year=${year}&group_by=${selectedFilter}&statistics=True`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseAstatistics(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataBstatistics = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section5/subsection2b/?report_year=${year}&group_by=${selectedFilter}&statistics=True`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponseBstatistics(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };
    getDataAstatistics();
    getDataBstatistics();
  }, [selectedFilter])

  return (
    <>
      <h2>
        5.2 Seriousness with which faculty viewed the outside offer
      </h2>
      <FilterDropdown setSelectedFilter={setSelectedFilter} />
      <p>
        Prior to receiving any counteroffer (if made), how seriously were you considering accepting the job offer you received?
      </p>

      {!transformedResponseA && <PageLoader />}

      {transformedResponseA &&
        <>
          <InstitutionN institutionN={institutionNresponseA} />
          <StackedByAnswer
            data={transformedResponseA}
            section="5.2a"
            sequential={true}
            selectedFilter={selectedFilter}
            institutionN={institutionNresponseA}
          />
          {
            apiResponseAstatistics &&
            <ApiResponseToCSVConverter
              apiResponse={apiResponseA}
              apiResponseB={apiResponseAstatistics}
              selectedFilter={selectedFilter}
              filename="5.2a_Seriousness"
              section="5.2a"
            />
          }
        </>
      }

      <p>
        Once you learned that you would not receive a counteroffer, how seriously were you considering accepting the job offer you received? (Asked of departures and retentions who sought but did not receive a counteroffer.)
      </p>

      {!transformedResponseB && <PageLoader />}

      {transformedResponseB &&
        <>
          <InstitutionN institutionN={institutionNresponseB} />
          <StackedByAnswer
            data={transformedResponseB}
            section="5.2b"
            sequential={true}
            institutionN={institutionNresponseB}
            selectedFilter={selectedFilter}
          />
          {
            apiResponseBstatistics &&
            <ApiResponseToCSVConverter
              apiResponse={apiResponseB}
              apiResponseB={apiResponseBstatistics}
              selectedFilter={selectedFilter}
              filename="5.2b_Seriousness"
              section="5.2b"
            />
          }
        </>
      }

      
    </>
  )
}

export default Seriousness;